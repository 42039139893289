import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";

const root = createRoot(document.getElementById("app"));

const RedirectCollection = (): null => {
  const { collectionId } = useParams();
  useEffect(() => {
    if (collectionId) {
      (window as any).location = `https://app.${
        !IS_PRODUCTION ? "dev." : ""
      }picme.com/up/${collectionId}`;
    } else {
      (window as any).location = "https://www.picme.com";
    }
  });
  return null;
};

const RedirectCatchAll = (): null => {
  useEffect(() => {
    (window as any).location = "https://www.picme.com";
  }, []);
  return null;
};

root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path=":collectionId" element={<RedirectCollection />} />
        <Route path="*" element={<RedirectCatchAll />} />
      </Routes>
    </BrowserRouter>
  </>
);
